<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2022-12-15 15:30:27
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2023-01-11 10:59:27
 * @FilePath: \web\src\views\certificate\signature\add.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div class="edit_insurance" v-loading='initLoading'>
    <div class="title">编辑电子签章</div>
    <el-form :model="form" ref="form" label-width="7rem" class="ruleForm" :rules="rules">
      <el-form-item label="企业名称" prop="companyId">
        <el-select v-model="form.companyId" placeholder="请选择企业名称" style="width:100%" @change="selectCompany">
          <el-option v-for="item in companyList" :key="item.id" :label="item.companyName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="taxId"> 
        <el-input v-model="form.taxId" disabled placeholder="请输入纳税人识别号"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="pwd">
        <el-input v-model="form.pwd" placeholder="请输入密码" ></el-input>
      </el-form-item>
      <el-form-item label="证书开始日期" prop="beginDate">
        <el-date-picker
          style="width:100%"
          v-model="form.beginDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择证书开始日期">
        </el-date-picker>
      </el-form-item>
        <el-form-item label="证书截止日期" prop="endDate">
          <el-date-picker
          style="width:100%"
          v-model="form.endDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="请选择证书截止日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="电子签章证书" required>
         <el-upload class="upload-demo" ref="upload" accept=".png,.jpg,.webp,.jpeg,.pfx" action="#" 
          :on-change="handleChange" :before-upload='beforeUpload' 
          :on-preview="handlePreview" :on-remove="handleRemove" 
          drag
          :http-request="httpRequest"
          :file-list="fileList" :auto-upload="false" >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
        <span class="errorTips" :class="{show: message != '' }">{{message}}</span>
      </el-form-item>

      <el-form-item class="btn">
        <el-button type="primary" @click="submitForm()" :loading="isLoading">保存</el-button>
        <el-button @click="back" class="cancel_btn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      className: "",
      form: {
        beginDate:'',//	证书开始日期
        caFile:'',//		证书文件
        endDate:'',//		证书截止日期
        pwd:'',//		密码

        userId:'',//		用户id
        companyId:'',//		公司Id
        taxId:'', // 纳税人识别号

      },
      rules: {
        companyId: [{ required: true, message: '请选择企业名称', trigger: 'blur' },],
        taxId: [{ required: true, message: '请输入纳税人识别号', trigger: 'blur' },],
        pwd: [{ required: true, message: '请输入密码', trigger: 'blur' },],

        beginDate: [{ required: true, message: '请选择证书开始日期', trigger: 'blur' },],
        endDate: [{ required: true, message: '请选择证书截止日期', trigger: 'blur' },],

        // certificateType: [{ required: true, message: '请选择证书类型', trigger: 'blur' },],
        // handlerName: [{ required: true, message: '请输入经办人姓名', trigger: 'blur' },],
        // handlerPhone: [{ required: true,validator: checkPhone, trigger: "blur" }],
      },
      isLoading: false,
      initLoading:false,
      companyList:[],

      fileList:[],
      fileData:'',
      message:'',
      status:false,

    }
  },

  methods: {
      httpRequest(param) {
        console.log(param.file);
      },
      handleChange(file, fileList){
        this.fileList = fileList.slice(-1);  //限定上传文件为一个
      },
      handleRemove(file, fileList) {
        this.fileData = '';
        this.status = false;
        this.message = '请选择上传文件';
      },
      beforeUpload(file, fileList) {
        let testFile = file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testFile === 'png'
        const extension2 = testFile === 'jpg'
        const extension3 = testFile === 'webp'
        const extension4 = testFile === 'pfx'
        const isLt1M = file.size / 1024 / 1024 < 1;

        if(!extension && !extension2 && !extension3 && !extension4) {
          this.message = '上传文件只能是png或jpg、webp、pfx格式！'
          this.status = false
        }else if (!isLt1M) {
          this.message = '上传Excel文件大小不能超过 1MB!'
          this.status = false
        }else{
          // let fd = new FormData()
          // fd.append('file', file)
          // fd.append('asId', this.getToken('asId'))
          // fd.append('userId', this.getToken('userId'))
          this.fileData = file;
          this.status = true;
          console.log(this.fileData,'this.form.caFile');
        }
      },
      handlePreview(file) {
        console.log(file);
      },
    
    selectCompany(value){
      let item = this.companyList.find((items) => { return items.id == value }) 
      this.form.taxId = item.taxId;
    },
  
    // 保存
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$refs.upload.submit();
          if ( moment(this.form.beginDate).format('YYYYMMDD')  > moment(this.form.endDate).format('YYYYMMDD')) {
            einvAlert.warning("提示","证书开始日期不可大于证书结束日期")
            return;
          }
          if (this.status) {
            let data = new FormData();
            data.append('beginDate',this.form.beginDate)
            data.append('endDate',this.form.endDate)
            data.append('pwd',this.form.pwd)
            data.append('companyId',this.form.companyId)
            data.append('taxId',this.form.taxId)
            data.append('userId', this.getToken('userId'))
            data.append('caFile',this.fileData)
            data.append('id',this.$route.query.id) //证书ID
            // let data = {...this.form, userId: this.getToken('userId'),  };
            this.isLoading = true;
            this.$store.dispatch("EditSign", data).then(res => {
              if (res.success) {
                this.isLoading = false;
                einvAlert.success("提示",'处理成功')
                this.$router.push('/certificate/signature')
              } else {
                this.$message.error(res.msg)
                this.isLoading = false
              }
            }).catch(err => {
              this.isLoading = false
            })
          }
        }
      });
    },
    back() {
      this.$router.push('/certificate/signature');
    },

    initList(){
      this.$store.dispatch("FindJoinCompany", { userId: this.getToken('userId'),}).then(res => {
            this.initLoading = true;
            if (res.success) {
              this.initLoading = false;
              this.companyList = res.data;
            } else {
              this.$message.error(res.msg)
              this.initLoading = false
            }
          }).catch(err => {
            this.initLoading = false
          })
    },
    findOne(){
        this.$store.dispatch("FindOneSign", {
          id : this.$route.query.id,
      }).then(res => {
      if (res.success) {
          this.form = {
            ...res.data,pwd:res.data.caPwd,
            beginDate: moment(res.data.createTime).format("YYYY-MM-DD"),
            endDate:moment(res.data.endDate).format("YYYY-MM-DD")
          }
          this.fileList = [{name:'电子签章证书',url:res.data.caPath}]
          this.status = true;
          this.fileData = res.data.caPath
      } else {
          einvAlert.error("提示",res.msg)
      }
      })
  },
  },

  mounted() {
    this.initList();
    if(this.$route.query.id){
        this.findOne();
    }
  },
  watch:{
      message: function (val) {
        if(val){
          setTimeout(()=>{
            this.message = '';
          },1500)
        }
      },
  }
}
</script>
<style lang="less" scoped>
.edit_insurance {
  height: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-weight: 700;
    color: #333333;
    font-size: 18px;
    padding: 18px 0;
    width: calc(100% - 40px);
    border-bottom: 1px solid #dcdcdc;
  }
  .ruleForm {
    margin: 40px auto;
    .el-form-item__content {
      .el-input__inner {
        width: 420px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      .el-input.is-disabled {
        .el-input__inner {
          background-color: #f6f6f6;
        }
      }
    }

    .btn {
      text-align: center;
      .el-button {
        width: 100px;
      }
      .cancel_btn {
        margin-left: 20px;
      }
    }
  }
}
.el-form-item__content {
  text-align: center !important;
}

.errorTips{
    display: block;
    font-size: 12px;
    color: #f56c6c;
    margin-bottom: -15px;
    margin-top: -5px;
  }

</style>